import React, {useEffect, useState} from 'react';
import {Link, navigate} from 'gatsby';
import axios from 'axios';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import ListBrand from '../../components/list-brand';
import ListLink from '../../components/list-link';
import Image from '../../components/Image';
import HtmlEscape from '../../components/EscapeHtml';

const NewsPage = (props) => {
  const { location } = props;
  const [pageId, setPageId] = useState('');
  const [content, setContent] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [pageDay, setPageDay] = useState('');
  const [nextPage, setNextPage] = useState('');
  const [prevPage, setPrevPage] = useState('');
  // Ajax設定
  const configJson = {
    method: 'get',
    url: `/update/news/${pageId}/setting.json`,
    headers: {
      Accept: 'application/json',
    },
    data: '',
  };
  const configText = {
    method: 'get',
    url: `/update/news/${pageId}/index.html`,
    headers: {
      Accept: 'text/html',
    },
    data: '',
  };
  // Ajax実行
  const dataFetch = async () => {
    axios(configJson)
      .then((response) => {
        setPageTitle(response.data.title);
        setPageDay(response.data.day);
        setNextPage(response.data.next);
        setPrevPage(response.data.prev);
      })
      .catch(() => {
        // console.log(error);
      });
    axios(configText)
      .then((response) => {
        setContent(response.data);
      })
      .catch(() => {
        // console.log(error);
      });
  };
  // Event
  useEffect(() => {
    setPageId(location.search.split('?id=')[1]);
    if (pageId) {
      dataFetch();
    }
    if (typeof pageId === 'undefined') {
      navigate('/404');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, location.search.split('?id=')[1]]);
  return (
    <Layout page="news-page detail">
      <Seo title="NEWS"/>
      <section className="page-head">
        <ListBrand/>
        <ListLink/>
        <h2 className="main-title"><span>NEWS</span></h2>
      </section>
      <section className="page-content flex-layout">
        <div className="main-content">
          <h3 className="sub-title"><span>{pageTitle}</span></h3>
          <p className="md:mb-11 mb-5 c-day">{pageDay}</p>
          {HtmlEscape(content)}
          <div className="under-pager">
            {prevPage === ''
              ? <p className="no-link prev">←</p>
              : <Link to={`/news/detail/?id=${prevPage}`} className="prev">←</Link>
            }
            <Link to="/news/" className="list-ttl">お知らせ一覧</Link>
            {nextPage === ''
              ? <p className="no-link next">→</p>
              : <Link to={`/news/detail/?id=${nextPage}`} className="next">→</Link>
            }
          </div>
        </div>
        <div className="side-list side-pager">
          <ul>
            <li>
              <Link to="/news/" className="list-ttl">
                一覧へ戻る
              </Link>
            </li>
            <li>
              {nextPage === ''
                ? <p className="no-link next">次のお知らせ</p>
                : <Link to={`/news/detail/?id=${nextPage}`} className="next">次のお知らせ</Link>
              }
            </li>
            <li>
              {prevPage === ''
                ? <p className="no-link prev">前のお知らせ</p>
                : <Link to={`/news/detail/?id=${prevPage}`} className="prev">前のお知らせ</Link>
              }
            </li>
          </ul>
        </div>
      </section>
      <section className="bread">
        <ul>
          <li>
            <Link to="/">
              <Image filename="ico/home.png" />
            </Link>
          </li>
          <li>
            <Link to="/news">お知らせ一覧</Link>
          </li>
        </ul>
      </section>
    </Layout>
  );
};

export default NewsPage;
