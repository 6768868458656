/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-danger */
/**
 * Type: ユーティリティーパーツ
 * What: dangerouslySetInnerHTMLのXSS対策
 */
import React from 'react';

const HtmlEscape = (content, className) => {
  const escapedData = content.replace(/javascript:/g, '');
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: escapedData }}
    />
  );
};

export default HtmlEscape;
